export default{
    _TOKEN_KEY: 'token',
    _LANG_KEY: 'lang',
    _MAINTENANCE: false,
    _UIN_BASE64: "MzIwNDc4NzMzOQ==",
    _FOLLOW_URL: "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIwNDc4NzMzOQ==#wechat_redirect",
    _ASSETS_HOST: process.env.NODE_ENV === 'production'
            ? 'https://wsapi.shipinhao.shop'
            : 'http://localhost:8787',
    _API_URL: process.env.NODE_ENV === 'production'
            ? 'https://wsapi.shipinhao.shop/api/'
            : 'http://localhost:8787/api/',
        //     :'http://43.139.139.170:8787/api/',
    _BASE_URL: process.env.NODE_ENV === 'production'
            ? 'https://shipinhao.shop/#/'
            : 'http://localhost:8080/#/',
}